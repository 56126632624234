import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
  Box,
  WebsiteWrapper,
  Text,
  H1,
  HR,
  Mq
} from '@parsleyhealth/cilantro-ui';
import { SanityHiltonVideo } from 'graphql-types';
import BlogFeaturedPosts from '../../components/Blog/BlogFeaturedPosts';
import BlogAuthor from '../../components/Blog/BlogAuthor';
import BlogGrid from '../../components/Blog/blog-grid';
import BlogPostStyles from '../../components/Blog/blog-post-styling';
import BlogSidebarCard from '../../components/Blog/blog-sidebar-card';
import BlogSectionTitle from '../../components/Blog/blog-section-title';
import { Space } from '@parsleyhealth/cilantro-ui/dist/types/theme';
import BlogShareSocialMedias from '../../components/Blog/blog-share-social-medias';
import BlockContent from '../../components/BlockContent';
import { getAuthorUrl } from '../../lib/blog-utils';
import { default as SEO } from '../../components/SEO';
import { useHiltonEducationConfig } from '../../hooks/use-hilton-education-config';
import { HiltonEducationBanner } from '../../components/HiltonEducation/hilton-education-banner';
import HiltonLayout from '../../components/HiltonEducation/hilton-layout';

interface HiltonVideoTemplateProps {
  data: {
    sanityHiltonVideo: SanityHiltonVideo;
    relatedVideos: {
      edges: {
        node: SanityHiltonVideo;
      }[];
    };
  };
}

const VideoContainer = styled(BlogPostStyles)`
  iframe {
    width: 100%;
    height: 220px;
  }

  ${Mq(1)} {
    iframe {
      width: 100%;
      height: 390px;
    }
  }

  ${Mq(3)} {
    iframe {
      width: 100%;
      height: 530px;
    }
  }
`;

const HiltonEducationVideoTemplate = ({ data }: HiltonVideoTemplateProps) => {
  const video = data?.sanityHiltonVideo;
  const {
    sidebarCard,
    sidebarTitle,
    metaDataHilton
  } = useHiltonEducationConfig();

  if (!video) {
    return null;
  }

  const {
    author,
    themes,
    content,
    id,
    slug,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    image,
    noindex,
    videoEmbedCode
  } = video;

  const category = themes && themes.length ? themes[0]?.title : null;
  const relatedVideos = data?.relatedVideos?.edges
    ?.map(p => p.node)
    .filter(p => p.id !== id && p.author !== null)
    .slice(0, 5)
    .sort(() => Math.random() - 0.5);
  const postSiteUrl = `${process.env.GATSBY_SITE_URL}/blog/videos/${slug.current}`;

  const leftPadding: Space = ['none', 'none', 'none', 'm'];

  let seoImage = null;
  if (metaImage) {
    seoImage = metaImage;
  } else if (image) {
    seoImage = image;
  }

  return (
    <>
      <SEO
        pageTitle={metaTitle ? metaTitle : title}
        pageDescription={
          metaDescription ? metaDescription : metaDataHilton.metaDescription
        }
        pageImage={seoImage}
        noindex={noindex}
      />
      <HiltonLayout>
        <WebsiteWrapper>
          <BlogGrid>
            <Box>
              <Box mb="m" pl={leftPadding}>
                <Text variant="pBold" color="leafyGreen" mb="xs">
                  {category?.toLocaleUpperCase()}
                </Text>
                <H1 mb="m">{title}</H1>
                <BlogShareSocialMedias url={postSiteUrl} />
              </Box>
              <VideoContainer
                dangerouslySetInnerHTML={{ __html: videoEmbedCode.trim() }}
              />
              <Box my="m" pl={leftPadding}>
                <BlogAuthor
                  name={author?.authorByLine}
                  url={getAuthorUrl(author?.slug?.current)}
                  image={author?.profileImage}
                  role={author?.role}
                />
              </Box>
              <Box pl={leftPadding}>
                <HR bg="greyLightBorder" />
                <BlogPostStyles>
                  <BlockContent blocks={content} />
                </BlogPostStyles>
                <Box my="l">
                  <BlogAuthor
                    name={author?.authorByLine}
                    url={getAuthorUrl(author?.slug?.current)}
                    image={author?.profileImage}
                    role={author?.role}
                    size="l"
                    bio={<BlockContent blocks={author?.bio} />}
                  />
                </Box>
                <HR bg="greyLightBorder" />
              </Box>
            </Box>
            <Box pl={['none', 'none', 'none', 'l']}>
              {sidebarCard && (
                <>
                  <BlogSectionTitle mb="s">{sidebarTitle}</BlogSectionTitle>
                  <BlogSidebarCard card={sidebarCard} prefix="siderail-1" />
                </>
              )}
              <BlogFeaturedPosts posts={relatedVideos} title="Related Videos" />
            </Box>
          </BlogGrid>
        </WebsiteWrapper>
        <HiltonEducationBanner />
      </HiltonLayout>
    </>
  );
};

export const query = graphql`
  fragment HiltonRelatedVideo on SanityHiltonVideo {
    id
    title
    slug {
      current
    }
    author {
      ...HiltonPostAuthor
    }
  }

  query HiltonEducationVideoQuery($sanityID: String, $categories: String) {
    sanityHiltonVideo(_id: { eq: $sanityID }) {
      id
      title
      metaTitle
      metaDescription
      metaImage {
        ...MetaImage
      }
      image {
        _type
        ...SanityImageFragment
      }
      themes {
        id
        title
        slug {
          current
        }
      }
      author {
        ...HiltonPostAuthor
      }
      publishedDate
      content: _rawContent
      videoEmbedCode
      medicallyReviewed
      noindex
      slug {
        current
      }
    }
    relatedVideos: allSanityHiltonVideo(
      filter: { themes: { elemMatch: { title: { regex: $categories } } } }
      limit: 9
    ) {
      edges {
        node {
          ...HiltonRelatedVideo
        }
      }
    }
  }
`;

export default HiltonEducationVideoTemplate;
